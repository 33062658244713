.experience {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
}

.title {
    text-align: center;
    text-decoration: underline;
    padding: 5vmin;
}

.section {
    margin-bottom: 40px;
}

.job-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.job-info {
    font-size: 16px;
    line-height: 1.5;
}

.job-info > ul {
    list-style-type: disc;
    margin-left: 20px;
}

.job-info > ul > li {
    margin-bottom: 5px;
}

.linkedin-link {
    display: inline-block;
    margin-top: 20px;
    font-size: 16px;
}

.linkedin-link a {
    text-decoration: none;
    color: #0077b5;
}

.linkedin-link img {
    height: 50px;
    width: 50px;
    margin-left: 5px;
    vertical-align: middle;
}

