.modal-title {
    text-align: center !important;
}

/* .modal-body {
    text-align: center;
} */

.modal-footer {
    justify-content: space-evenly;
    text-align: center;
    text-decoration: none;
}

.modal-footer a {
    text-decoration: none;
    color: black;
}

.modal-footer img {
    height: 50px;
    width: 50px;
}

.icons {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 20px;
    justify-items: center;
    justify-content: space-evenly;
}

.icons img {
    height: 50px;
    width: 50px;
}

.icons div {
    text-align: center;
}

.display-img {
    width: 90% !important; 
    height: auto !important;
    margin: 0 auto;
    display: block;
    padding-top: 3vmin;
    padding-bottom: 3vmin;
}

.tech-title {
    padding: 1.5vmin;
    text-align: center;
}