.projects-title {
    text-align: center;
    padding-top: 5vmin;
    text-decoration: underline;
}

.projects-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 5vmin; 
    padding: 5vmin;
}

.project-card {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.project-card:hover {
    transform: scale(1.05);
}


@media (min-width: 992px) {
    .projects-grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (max-width: 991px) {
    .projects-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .projects-grid {
        grid-template-columns: repeat(1, 1fr);
    }
}
